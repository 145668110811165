var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "login-form"
  }, [_c('div', {
    staticClass: "min-h-75px"
  }, [_vm.message ? _c('vue-inline-alert', {
    attrs: {
      "message": _vm.message,
      "message-type": "success"
    }
  }) : _vm._e(), _vm.errorMessage ? _c('vue-inline-alert', {
    attrs: {
      "message": _vm.errorMessage,
      "message-type": "error"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "pt-lg-0 pt-5"
  }, [_c('div', {
    staticClass: "mb-20 font-size-h4"
  }, [_c('router-link', {
    staticClass: "primary--text bold-text d-flex align-center mb-2",
    attrs: {
      "to": {
        name: 'signin'
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "color": "primary"
    }
  }, [_vm._v("keyboard_arrow_left")]), _c('span', {
    staticClass: "text-decoration-underline"
  }, [_vm._v(" Back to Signin ")])], 1)], 1), _c('h3', {
    staticClass: "bold-text darkGrey--text font-size-h2 mb-2"
  }, [_vm._v(" Forgot Password? ")]), _c('span', {
    staticClass: "bold-text darkGrey--text font-size-h4"
  }, [_vm._v("It happens to the best of us.")])]), _c('forget-password', {
    attrs: {
      "email": _vm.form.email || _vm.email
    },
    on: {
      "success": function success($event) {
        return _vm.$router.push({
          name: 'dashboard'
        });
      }
    }
  }), _c('vue-snack-bar')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }