var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.step === 1 ? _c('v-form', {
    staticClass: "mt-5",
    on: {
      "submit": function submit($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.onSubmit.apply(null, arguments);
      }
    }
  }, [_vm.getAuthMessage.message ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('vue-inline-alert', {
    attrs: {
      "message": _vm.getAuthMessage.message,
      "message-type": _vm.getAuthMessage.type
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "my-3 darkGrey--text font-size-h3"
  }, [_vm._v(" Request for a new password ")]), _c('div', {
    staticClass: "trasparent",
    attrs: {
      "disabled": _vm.busy,
      "tile": "",
      "flat": ""
    }
  }, [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|email',
      expression: "'required|email'"
    }],
    attrs: {
      "filled": "",
      "id": "forgetPasswordField",
      "placeholder": "Your email address"
    },
    model: {
      value: _vm.form.email,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  }, 'v-text-field', _vm.veeValidate('Email', 'Email'), false)), _c('v-btn', {
    staticClass: "main primary mt-4 mb-2 mx-auto",
    attrs: {
      "type": "submit",
      "loading": _vm.busy,
      "disabled": _vm.busy || _vm.errors.any() || !_vm.form.email
    }
  }, [_vm._v(" Send ")])], 1)], 1) : _vm.step === 2 ? _c('div', {
    staticClass: "text-left mt-10"
  }, [_c('verification-code-checker', {
    attrs: {
      "email": _vm.email,
      "action": "reset_password",
      "reset-password": ""
    },
    on: {
      "next": function next($event) {
        return _vm.setToken($event);
      },
      "error": _vm.setErrorMessage,
      "change-email": function changeEmail($event) {
        _vm.step = 1;
      }
    }
  })], 1) : _vm.step === 3 ? _c('div', [_c('div', {
    staticClass: "my-3 font-size-h3"
  }, [_vm._v("Change your password")]), _c('reset-password-form', {
    attrs: {
      "from-auth-check": "",
      "reset-token": _vm.token
    },
    on: {
      "success": function success($event) {
        return _vm.$emit('success', $event);
      },
      "error": _vm.setErrorMessage
    }
  })], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }