<template>
  <div>
    <v-row>
      <v-col v-if="getAuthMessage.message" cols="12">
        <vue-inline-alert
          :message="getAuthMessage.message"
          :message-type="getAuthMessage.type"
        ></vue-inline-alert>
      </v-col>
      <v-col class="py-0 text-center" cols="12">
        <div class="font-size-h3">Please check your email inbox</div>
        <div class="my-3 bodyFont">
          <span v-if="!fromVerifyOwnership">
            We've emailed verification code to
            {{ verifyEmail }}
            <small v-if="!fromDeleteAccount && !fromVerifyOwnership" @click="changeEmail" class="primary--text cursor-pointer"
              >(Change)</small
            >
          </span>
        </div>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-row>
          <v-col class="py-0 mx-auto" cols="10" md="6" sm="12">
            <v-text-field
              v-model="verificationCode"
              :max="99999"
              autofocus
              placeholder="Code"
              prepend-inner-icon="mdi-email-outline"
              type="number"
              class="px-3 mx-auto font-weight-bold text-h5"
              outlined
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-btn :disabled="!verificationCode" @click="onConfirmVerify" :loading="busy" color="primary" outlined
          >Verify</v-btn
        >
      </v-col>
      <v-col class="text-center" cols="12">
        <div>
          Didn't get the email?
          <span
            v-if="!resendLoading"
            @click="resendCode"
            class="primary--text cursor-pointer"
          >
            resend the email
          </span>
          <span v-else> ... </span>
        </div>
        <social-auth
          v-if="!fromVerifyOwnership && !fromDeleteAccount"
          @loading="busy = $event"
          button-text="Sign up with"
          @success="$emit('success')"
          default-signin
        ></social-auth>
      </v-col>
      <v-slide-y-transition>
        <v-col v-if="showResendNote" class="mx-auto text-left pl-2" cols="9">
          <div>
            <div>Note: If you didn't receive our verification email:</div>
            <ul>
              <li>Confirm that your e-mail address is correct.</li>
              <li>Check your spam or junk e-mail folder.</li>
            </ul>
          </div>
        </v-col>
      </v-slide-y-transition>
    </v-row>
  </div>
</template>

<script>
import {
  ACTIVATE_ACCOUNT,
  RESEND_TOKEN,
  VERIFY_TOKEN,
} from "@/store/users/auth.module";
import { mapGetters, mapMutations } from "vuex";
import SocialAuth from "@/view/components/User/SocialAuth";
import { segmentEvents } from "@/consts/segmentEventConst";
import { TRANSFER_OWNERSHIP } from "@/store/team/members.module";

export default {
  components: {
    SocialAuth,
  },
  props: {
    fromInstall: {
      type: Boolean,
      default: false,
    },
    memberDetail: {
      type: Object,
      default() {
        return {};
      },
    },
    fromDeleteAccount: {
      type: Boolean,
      default: false
    },
    fromVerifyOwnership: {
      type: Boolean,
      default: false,
    },
    email: {
      type: String,
      default: "",
    },
    action: {
      type: String,
      default: "activate_account",
    },
    resetPassword: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      verificationCode: "",
      verifyEmail: "",
      busy: false,
      errorMessage: "",
      resendLoading: false,
      showResendNote: false,
    };
  },
  computed: {
    ...mapGetters({
      getAuthMessage: "getAuthMessage",
      getRedirectedFromPlan: "getRedirectedFromPlan",
    }),
  },
  created() {
    this.verifyEmail = this.email || this.$route.query.email || "";
  },
  beforeDestroy() {
    this.setAuthMessage({ message: "", type: "" });
  },
  methods: {
    ...mapMutations({
      setAuthMessage: "setAuthMessage",
      setActivateErrorMessage: "setActivateErrorMessage",
    }),
    resendCode() {
      if (this.action === 'transfer_ownership') {
        this.resendSendCodeForOwner();
      } else {
        this.resendEmail();
      }
    },
    resendEmail() {
      if (this.resendLoading) return;
      this.resendLoading = true;
      this.setAuthMessage({ message: "", type: "" });
      let dataToPost = {
        action: this.action,
        invite_id: this.$route.params.invite_id || "",
        email: this.verifyEmail,
      };
      this.$store
        .dispatch(RESEND_TOKEN, dataToPost)
        .then((response) => {
          this.setAuthMessage({ message: response.message, type: "success" });
          this.showResendNote = true;
          this.resendLoading = false;
        })
        .catch((err) => {
          this.setAuthMessage({ message: err.message, type: "error" });
          this.resendLoading = false;
        });
    },
    resendSendCodeForOwner() {
      if (this.resendLoading) return;
      this.resendLoading = true;
      this.setAuthMessage({ message: "", type: "" });
      let dataToPost = {
        user_id: this.memberDetail?.user_id,
        message: this.memberDetail.message,
      };
      this.$store
        .dispatch(TRANSFER_OWNERSHIP, dataToPost)
        .then((response) => {
          (this.showResendNote = true),
            this.setAuthMessage({ message: response.message, type: "success" });
          this.notifyUserMessage({ message: response.message });
          this.resendLoading = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.setAuthMessage({ message: err.message, type: "error" });
          this.resendLoading = false;
        });
    },
    changeEmail() {
      this.$emit("change-email", this.verifyEmail);
    },
    onConfirmVerify() {
      if (this.fromVerifyOwnership || this.resetPassword || this.fromDeleteAccount) {
        this.verifyCode();
      } else {
        this.activateUser(`${this.verificationCode}`);
      }
    },
    verifyCode() {
      this.setAuthMessage({ message: "", type: "" });
      if (this.busy) return;
      this.busy = true;
      this.$emit("error", null);
      this.errorMessage = null;
      // let actions = ['verify_reset_password_token', 'transfer_ownership']
      if (this.verificationCode && this.verificationCode.length <= 5) {
        let dataToPost = {
          action: this.action,
          token: `${this.verificationCode}`,
          email: this.verifyEmail,
        };
        if (!dataToPost.email) {
          delete dataToPost.email;
        }

        this.$store
          .dispatch(VERIFY_TOKEN, dataToPost)
          .then((response) => {
            this.busy = false;
            this.$emit("next", {code: `${this.verificationCode}`, message: response.message })
            if (!this.resetPassword && !this.fromVerifyOwnership && !this.fromDeleteAccount) {
              this.activateUser(`${this.verificationCode}`);
            }
          })
          .catch((err) => {
            this.busy = false;
            this.$emit("error", err.message);
            this.setAuthMessage({ message: err.message, type: "error" });
            this.errorMessage = err.message;
          });
      } else {
        this.notifyErrorMessage("Please add 5 digit verification code");
        this.busy = false;
      }
    },
    setVerificationCode(value) {
      if (value) {
        this.verificationCode = value.substring(0, 5);
      }
    },
    activateUser(token) {
      this.setActivateErrorMessage(null);
      if(this.busy) return
      this.busy = true
      this.$store
        .dispatch(ACTIVATE_ACCOUNT, token)
        .then((response) => {
          if (this.fromInstall) {
            this.$emit(
              "success",
              response.message || "Your account successfully activated."
            );
          } else if (this.$route.name === "verify") {
            this.$router
              .push({
                name: "dashboard",
              })
              .catch(() => {});
          } else {
            this.$router
              .push({
                name: "start",
                params: {
                  message:
                    response.message || "Your account successfully activated.",
                },
              })
              .catch(() => {});
          }
          this.busy = false;
          if (this.getRedirectedFromPlan) {
            this.logEvent(segmentEvents.REDIRECT_LANDING, {
              plan: this.getRedirectedFromPlan,
            });
          }
        })
        .catch((err) => {
          this.busy = false;
          this.notifyErrorMessage(err.message);
        });
    },
  },
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
