<template>
  <div class="login-form">
    <div class="min-h-75px">
      <vue-inline-alert
        v-if="message"
        :message="message"
        message-type="success"
      />
      <vue-inline-alert
        v-if="errorMessage"
        :message="errorMessage"
        message-type="error"
      />
    </div>
    <div class="pt-lg-0 pt-5">
      <div class="mb-20 font-size-h4">
        <router-link
          class="primary--text  bold-text d-flex align-center mb-2"
          :to="{ name: 'signin' }"
        >
          <v-icon left color="primary">keyboard_arrow_left</v-icon>
          <span class="text-decoration-underline">
            Back to Signin
          </span>
        </router-link>
      </div>
      <h3 class="bold-text darkGrey--text font-size-h2 mb-2">
        Forgot Password?
      </h3>
      <span class="bold-text darkGrey--text font-size-h4"
        >It happens to the best of us.</span
      >
    </div>
    <forget-password
      :email="form.email || email"
      @success="$router.push({ name: 'dashboard' })"
    ></forget-password>
    <vue-snack-bar></vue-snack-bar>
  </div>
</template>

<script>
import veeValidate from "@/mixins/veeValidate";
import ForgetPassword from "@/view/components/User/ForgetPassword";

export default {
  components: { ForgetPassword },
  mixins: [veeValidate],
  name: "forgot-password-page",
  data() {
    return {
      form: {
        email: "",
      },
      message: "",
      step: 1,
      email: "",
      token: "",
      errorMessage: null,
      emailCheck: false,
      changePassword: false,
      busy: false,
    };
  },
  watch: {
    errorMessage(v) {
      v ? (this.message = null) : null;
    },
    message(v) {
      v ? (this.errorMessage = null) : null;
    },
  },
  created() {
    this.message = "";
    if (this.$route && this.$route.query.step) {
      this.step = parseInt(this.$route.query.step);
    }
    if (this.$route.query.email) {
      this.form.email = this.$route.query.email || "";
    }
  },
  methods: {
    resetForm() {
      this.form = {
        email: "",
      };
    },
    setErrorMessage(message) {
      this.errorMessage = message;
    },
    updateUrl() {
      if (window && window.history.pushState) {
        const newUrl =
          window.location.protocol +
          "//" +
          window.location.host +
          window.location.pathname +
          "?step=" +
          this.step +
          "&email=" +
          this.email;
        window.history.pushState({ path: newUrl }, "", newUrl);
      }
    },
  },
};
</script>
