<template>
    <div>
        <v-form v-if="step === 1" class="mt-5" @submit.stop.prevent="onSubmit">
            <v-col v-if="getAuthMessage.message" cols="12">
                <vue-inline-alert :message="getAuthMessage.message" :message-type="getAuthMessage.type"></vue-inline-alert>
            </v-col>
            <div class="my-3 darkGrey--text font-size-h3">
                Request for a new password
            </div>
            <div :disabled="busy" tile flat class="trasparent">
                <v-text-field filled id="forgetPasswordField" placeholder="Your email address" v-model="form.email" v-validate="'required|email'"
                    v-bind="veeValidate('Email', 'Email')" />
                <v-btn type="submit" class="main primary mt-4 mb-2 mx-auto" :loading="busy" :disabled="busy || errors.any() || !form.email">
                    Send
                </v-btn>
            </div>
        </v-form>
        <div class="text-left mt-10" v-else-if="step === 2">
            <verification-code-checker :email="email" action="reset_password" reset-password @next="setToken($event)" @error="setErrorMessage"
                @change-email="step = 1"></verification-code-checker>
        </div>
        <div v-else-if="step === 3">
            <div class="my-3 font-size-h3">Change your password</div>
            <reset-password-form from-auth-check @success="$emit('success', $event)" @error="setErrorMessage" :reset-token="token"></reset-password-form>
        </div>
    </div>
</template>

<script>
import { FORGOT_PASSWORD, PURGE_AUTH_DATA } from "@/store/users/auth.module";
import ResetPasswordForm from "@/view/components/User/ResetPasswordForm";
import VerificationCodeChecker from "@/view/components/Common/VerificationCodeChecker";
import veeValidate from "@/mixins/veeValidate";
import { mapGetters, mapMutations } from "vuex";

export default {
    name: "forget-password-page",
    components: { ResetPasswordForm, VerificationCodeChecker },
    mixins: [veeValidate],
    props: {
        fromAuthCheck: {
            type: Boolean,
            default: false,
        },
        email: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            form: {
                email: "",
            },
            message: "",
            step: 1,
            veryEmail: "",
            token: "",
            errorMessage: null,
            emailCheck: false,
            changePassword: false,
            busy: false,
            loading: false,
        };
    },
    computed: {
        ...mapGetters({
            getAuthMessage: "getAuthMessage",
        }),
    },
    created() {
        this.setAuthMessage({ message: "", type: "" });
        if (this.$route && this.$route.query.step) {
            this.step = parseInt(this.$route.query.step);
        }
    },
    mounted() {
        if (this.email || this.$route.query.email) {
            this.form.email = this.email || this.$route.query.email || "";
            this.verifyEmail = this.email || this.$route.query.email || "";
        }
    },
    methods: {
        ...mapMutations({
            setAuthMessage: "setAuthMessage",
        }),
        setErrorMessage(message) {
            this.errorMessage = message;
        },
        setToken(token) {
            this.token = token?.code;
            this.step = 3;
            this.loaing = false;
            // if (this.loading) return;
            // this.loading = true;
            // this.setAuthMessage({ message: "", type: "" });
            // this.$store
            //   .dispatch(VERIFY_RESET_PASSWORD_TOKEN, resetToken)
            //   .then(({ token }) => {
            //     this.step = 3;
            //     this.token = token;
            //     this.loading = false;
            //   })
            //   .catch((error) => {
            //     this.loading = false;
            //     this.setAuthMessage({ message: error.message, type: "error" });
            //   });
        },
        updateUrl() {
            if (window && window.history.pushState) {
                const newUrl =
                    window.location.protocol +
                    "//" +
                    window.location.host +
                    window.location.pathname +
                    "?step=" +
                    this.step +
                    "&email=" +
                    this.veryEmail;
                window.history.pushState({ path: newUrl }, "", newUrl);
            }
        },
        async onSubmit() {
            this.setAuthMessage({ message: "", type: "" });
            if (this.errors.any() || this.busy) {
                return;
            }

            const email = this.form.email;

            // clear existing errors
            this.errorMessage = null;
            await this.$store.dispatch(PURGE_AUTH_DATA);
            this.busy = true;
            this.$store
                .dispatch(FORGOT_PASSWORD, { email })
                .then((response) => {
                    this.email = this.form.email;
                    this.step = 2;
                    this.setAuthMessage({ message: response.message, type: "success" });
                    this.message = response.message || "Email sent";
                    this.busy = false;
                    this.$validator.errors.clear();
                })
                .catch((err) => {
                    this.errorMessage = err.message;
                    this.setAuthMessage({ message: err.message, type: "error" });
                    // this.notifyErrorMessage(err.message);
                    this.busy = false;
                    this.$validator.errors.clear();
                });
        },
    },
};
</script>
