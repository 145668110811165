var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', [_vm.getAuthMessage.message ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('vue-inline-alert', {
    attrs: {
      "message": _vm.getAuthMessage.message,
      "message-type": _vm.getAuthMessage.type
    }
  })], 1) : _vm._e(), _c('v-col', {
    staticClass: "py-0 text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "font-size-h3"
  }, [_vm._v("Please check your email inbox")]), _c('div', {
    staticClass: "my-3 bodyFont"
  }, [!_vm.fromVerifyOwnership ? _c('span', [_vm._v(" We've emailed verification code to " + _vm._s(_vm.verifyEmail) + " "), !_vm.fromDeleteAccount && !_vm.fromVerifyOwnership ? _c('small', {
    staticClass: "primary--text cursor-pointer",
    on: {
      "click": _vm.changeEmail
    }
  }, [_vm._v("(Change)")]) : _vm._e()]) : _vm._e()])]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "py-0 mx-auto",
    attrs: {
      "cols": "10",
      "md": "6",
      "sm": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "px-3 mx-auto font-weight-bold text-h5",
    attrs: {
      "max": 99999,
      "autofocus": "",
      "placeholder": "Code",
      "prepend-inner-icon": "mdi-email-outline",
      "type": "number",
      "outlined": ""
    },
    model: {
      value: _vm.verificationCode,
      callback: function callback($$v) {
        _vm.verificationCode = $$v;
      },
      expression: "verificationCode"
    }
  })], 1)], 1), _c('v-btn', {
    attrs: {
      "disabled": !_vm.verificationCode,
      "loading": _vm.busy,
      "color": "primary",
      "outlined": ""
    },
    on: {
      "click": _vm.onConfirmVerify
    }
  }, [_vm._v("Verify")])], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_vm._v(" Didn't get the email? "), !_vm.resendLoading ? _c('span', {
    staticClass: "primary--text cursor-pointer",
    on: {
      "click": _vm.resendCode
    }
  }, [_vm._v(" resend the email ")]) : _c('span', [_vm._v(" ... ")])]), !_vm.fromVerifyOwnership && !_vm.fromDeleteAccount ? _c('social-auth', {
    attrs: {
      "button-text": "Sign up with",
      "default-signin": ""
    },
    on: {
      "loading": function loading($event) {
        _vm.busy = $event;
      },
      "success": function success($event) {
        return _vm.$emit('success');
      }
    }
  }) : _vm._e()], 1), _c('v-slide-y-transition', [_vm.showResendNote ? _c('v-col', {
    staticClass: "mx-auto text-left pl-2",
    attrs: {
      "cols": "9"
    }
  }, [_c('div', [_c('div', [_vm._v("Note: If you didn't receive our verification email:")]), _c('ul', [_c('li', [_vm._v("Confirm that your e-mail address is correct.")]), _c('li', [_vm._v("Check your spam or junk e-mail folder.")])])])]) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }